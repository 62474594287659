import React, { useEffect } from "react";
import LoginForm from "./LoginForm";
import { useState } from "react";
import FormErrors from "./FormErrors";

const RecoveryForm = () => {

    const fetchUrl = process.env.REACT_APP_MAIN_URL + "views/ajax/site.php?react";

    useEffect(() => {

        let callback = token => setTokenCSRF(token);
        LoginForm.requestInitialTokenCSRF(fetchUrl, callback);

    }, [fetchUrl]);

    const [csrfToken, setTokenCSRF] = useState("");
    const [email, changeEmail] = useState("");
    const [errors, setErrors] = useState([]);
    const [animateErrors, startAnimateErrors] = useState(false);
    const [successMessage, showSuccessMessage] = useState("");

    const sendForm = () => {
        
        let post = {recover_password: email, csrf_individual_token: csrfToken};
        let postHeaders = {"Content-Type": "application/json"};

        fetch(fetchUrl, {method: "POST", headers: postHeaders, body: JSON.stringify(post)})
            .then(res => res.json())
            .then(result => {

                startAnimateErrors(false);
                setErrors(result.errors);
                setTimeout(() => startAnimateErrors(true), 50);

                if(result.success)
                    showSuccessMessage(result.message);
        });
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        sendForm()
    }

    return (
        <div className="form-block registration-block clearfix">
        <div className="form-wrapper">
        <div className="title">Forgot password?</div>
        <p className="annotation">Recover your password via email.</p>
        {
            (errors.length > 0) && <FormErrors errors={errors} animate={animateErrors} />
        }
        { 
            (successMessage !== "") && <div className="form-success">{successMessage}</div> 
        }
        <form id="recover-password-form" onSubmit={handleFormSubmit}>
            <div className="field-input">
                <input type="text"name="email" placeholder="Email" value={email} onChange={(e) => changeEmail(e.target.value)} />
            </div>
            <div className="form-buttons">
                <input type="hidden" name="csrf_individual_token" value={csrfToken} /><br />
                {
                    (successMessage === "") && 
                        <input id="recover-password-button" type="button" onClick={sendForm} value="Send new password" className="button sign-up" />
                }
            </div>
        </form>
        </div>
    </div>        
    )
}

export default RecoveryForm;