import React from "react";
import { Link } from "react-router-dom";

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            errors: [], 
            animateErrors: false,
            email: "",
            password: "",
            remember: false,
            csrfToken: ""
        };

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

        this.fetchUrl = process.env.REACT_APP_MAIN_URL + "views/ajax/site.php?react";
    }

    componentDidMount() {

        if(this.state.csrfToken)
            return;
        
        let callback = token => this.setState({csrfToken: token});
        LoginForm.requestInitialTokenCSRF(this.fetchUrl, callback);
    }
    
    getInitialTokenCSRF(url, callback) {

        let postHeaders = {"Content-Type": "application/json", "X-Requested-With": "XMLHttpRequest"};
        let post = JSON.stringify({start: 1});

        fetch(url, {method: "POST", headers: postHeaders, body: post})
        .then(response => {
            return response.json();
        })
        .then(result => {
            callback(result.token);
        });
    }

    handleEmailChange = (event) => {
        this.setState({email: event.target.value});
    }

    handlePasswordChange = (event) => {
        this.setState({password: event.target.value});
    }

    handleCheckboxChange = (event) => {
        this.setState({remember: event.target.checked});
    }

    handleSubmit = () => {

        this.setState({animateErrors: false});

        let post = {login_form: 1, email: this.state.email, 
                    password: this.state.password, remember_me: this.state.remember,
                    csrf_individual_token: this.state.csrfToken};
        
        post = JSON.stringify(post);
        let postHeaders = {"Content-Type": "application/json"};

        fetch(this.fetchUrl, {method: "POST", headers: postHeaders, body: post})
            .then(res => res.json())
            .then(result => {
                this.setState({errors: result.errors});
                setTimeout(() => this.setState({animateErrors: true}), 50);

                if(result.success)
                    window.location.href = result.link;
            });
    }

    render() {

        let errorsCss = "form-errors react";

        if(this.state.animateErrors)
            errorsCss += " animated";

        return (
            <>
            <div className="form-block login-block clearfix">
            <div className="form-wrapper">
                <div className="logo"><a href="/"> </a></div>
                <p className="annotation">Be sure that your clients<br />can book your time <span className="color-highlight">24/7</span>.</p>
                { 
                    this.state.errors.length > 0 && 

                        <div key="errors" className={errorsCss}> 
                            { 
                                this.state.errors.map((value, index) => 
                                    <p key={value}>{value}</p> )
                            }
                        </div>
                }
                <form id="login-form">
                  <div className="field-input">
                      <input type="text" value={this.state.email} name="email" 
                             placeholder="Email" onChange={this.handleEmailChange} />
                  </div>
                  <div className="field-input">
                      <input type="password" value={this.state.password} name="password" 
                             placeholder="Password" onChange={this.handlePasswordChange} />
                      <a href="/recovery/" className="underline-link">Forgot password?</a>
                  </div>
                  <div className="remember-me">
                     <input type="checkbox" checked={this.state.remember} name="remember_me" 
                            id="remember-me" onChange={this.handleCheckboxChange} />
                     <label htmlFor="remember-me">Remember me</label>
                  </div>                  
                  <div className="form-buttons">
                      <input type="hidden" name="csrf_individual_token" value={this.state.csrfToken} />
                      <input id="login-button" onClick={this.handleSubmit} type="button" defaultValue="Log in" className="button login" />
                  </div>                                    
               </form>
               <hr />
               <div className="account-create">
                  <p>Have no account yet?</p>
                  <Link to="/create-account/" className="button register">Register</Link>
               </div>
            </div>
         </div>
         </>
        )
    }
}

LoginForm.requestInitialTokenCSRF = function(url, callback) {

    let postHeaders = {"Content-Type": "application/json", "X-Requested-With": "XMLHttpRequest"};
    let post = JSON.stringify({start: 1});

    fetch(url, {method: "POST", headers: postHeaders, body: post})
    .then(response => {
        return response.json();
    })
    .then(result => {
        callback(result.token);
    });    
}

export default LoginForm;