import React from "react";
import Cookies from "universal-cookie";

class CookiesMessage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {showMessage: true};
        this.hideMessage = this.hideMessage.bind(this);
    }

    hideMessage() {
        this.setState({showMessage: false});

        const cookies = new Cookies();
        const time = new Date();

        time.setDate(time.getDate() + 180);
        cookies.set("cookies_message", true, {path: "/", expires: time});
    }

    render() {
        return (
            <>
            {
                this.state.showMessage &&  
                <div className="cookie-message">
                    <div className="wrapper-middle">
                        <span>By using our site, you acknowledge that you have read and understand 
                            our <a href={`${process.env.REACT_APP_MAIN_URL}privacy-policy/`} rel="noreferrer" target="_blank">Privacy and Cookie Policy</a> and 
                            our <a href={`${process.env.REACT_APP_MAIN_URL}terms-of-service/`} rel="noreferrer" target="_blank">Terms of Service</a>.
                        </span>
                        <div className="close" onClick={this.hideMessage}></div>
                    </div>
                </div>
            }
            </>
        );
    }
}

export default CookiesMessage;