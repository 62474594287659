import React from "react";
import CookiesMessage from "./parts/CookiesMessage";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const showCookiesMessage = !Boolean(cookies.get("cookies_message"));
const current_year = new Date().getFullYear();

class Footer extends React.Component {
    
    render(){
        
        return (
            <>
            <div id="footer">
                <p className="copyright">Copyright &copy; {current_year} Getin.pro. All rights reserved.</p>
                <a href={`${process.env.REACT_APP_MAIN_URL}terms-of-service/`} className="terms underline-link">Terms of service</a>
                <span>|</span>
                <a href={`${process.env.REACT_APP_MAIN_URL}privacy-policy/`} className="underline-link">Privacy Policy</a>
                <span>|</span>
                <a href="https://mv-tracker.com" rel="noreferrer" target="_blank">Supported by MV tracker</a>
            </div>
            <div className="to-top"></div>
            { showCookiesMessage && <CookiesMessage /> }
            </>
    )};
}

export default Footer;