
const FormErrors = (props) => {

    let css = "form-errors react";

    if(props.animate)
        css += " animated";

    return (

        <div key="errors" className={css}>
        { 
            props.errors.map((value) => 
                <p key={value}>{value}</p> )
        }
        </div>
    );
}

export default FormErrors;