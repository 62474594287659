import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter,  Routes,  Route } from 'react-router-dom';

import Register from './components/Register';
import Login from './components/Login';
import Recover from './components/Recover';
import Error404 from './components/Error404';

import './media/css/default.css';
import './media/css/site-styles.css';
import './media/css/touch-menu-la.css';
import './media/css/media.css';

// import Cookies from "universal-cookie";
// const cookies = new Cookies();
// const redirectFormAuth = cookies.get("auth_redirect");
// console.log(`redirect: ${redirectFormAuth}`)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  //<React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login/" element={<Login />} />
        <Route path="/recovery/" element={<Recover />} />
        <Route path="/create-account/" element={<Register />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  //</React.StrictMode>
);
