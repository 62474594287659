import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import LoginForm from './parts/LoginForm';
import FormErrors from './parts/FormErrors';

function Register(props) {

    const fetchUrl = process.env.REACT_APP_MAIN_URL + "views/ajax/site.php?react";
    const mainSiteUrl = process.env.REACT_APP_MAIN_URL;

    const [csrfToken, setTokenCSRF] = useState("");
    const [formData, changeFormData] = useState({name: "", email: "", password: "", remember: true, agree: false});
    const [errors, setErrors] = useState([]);
    const [animateErrors, startAnimateErrors] = useState(false);

    const handleChange = event => {

        const {name, value} = event.target;

        changeFormData(prevState => {

            let newState = {...prevState};

            if(name === "remember" || name === "agree")
                newState[name] = !prevState[name];
            else
                newState[name] = value;

            return newState;
        });
    }

    useEffect(() => {

        let callback = token => setTokenCSRF(token);
        LoginForm.requestInitialTokenCSRF(fetchUrl, callback);

    }, [fetchUrl]);

    const submitForm = () => {

        let time_offset = new Date().getTimezoneOffset();
        time_offset = time_offset === 0 ? 0 : -time_offset;
        time_offset /= 60;

        let post = {...formData, submit_registration: 1, csrf_individual_token: csrfToken, time_offset: time_offset};
        post["agree"] = post["agree"] ? "1" : "";
        post["remember"] = post["remember"] ? "1" : "";

        let postHeaders = {"Content-Type": "application/json"};

        fetch(fetchUrl, {method: "POST", headers: postHeaders, body: JSON.stringify(post)})
            .then(res => res.json())
            .then(result => {

                startAnimateErrors(false);
                setErrors(result.errors);
                setTimeout(() => startAnimateErrors(true), 50);

                if(result.success && result.link !== "")
                    window.location.href = result.link;
        });
    }

    return (
        <div>
            <div id="container">
                <Header />
                <div id="content">
                    <div className="form-block registration-block clearfix">
                        <div className="form-wrapper">
                            <div className="title">Create account</div>
                            <p className="annotation">Be sure that your clients can book<br /> your time <span className="color-highlight">24/7</span>.</p>
                            {
                                (errors.length > 0) && <FormErrors errors={errors} animate={animateErrors} />
                            }
                            <form id="registration-form">
                                <div className="field-input">
                                    <input type="text" name="name" placeholder="Your name" value={formData.name} onChange={handleChange} />
                                </div>
                                <div className="field-input">
                                    <input type="text" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                                </div>
                                <div className="field-input">
                                    <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} />
                                    <span className="red">Min 6 symbols, letters & numbers</span>
                                </div>
                                <p className="red">— All fields required</p>
                                <div className="remember-me">
                                    <input type="checkbox" name="remember" id="remember-me" checked={formData.remember} onChange={handleChange} />
                                    <label htmlFor="remember-me">Remember me</label>
                                </div>
                                <div className="policy-note">
                                    <input type="checkbox" name="agree" checked={formData.agree} onChange={handleChange} /> I agree with&nbsp;
                                        <a target="_blank" rel="noreferrer" href={`${mainSiteUrl}terms-of-service/`}>terms of service</a>&nbsp;
                                        and with processing of my personal data by Getin.pro described in&nbsp;
                                        <a target="_blank" rel="noreferrer" href={`${mainSiteUrl}privacy-policy/`}>policy page</a>
                                </div>
                                <div className="form-buttons">
                                    <input type="hidden" name="csrf_individual_token" value={csrfToken} />
                                    <input type="button" id="submit-registration" value="Register" className="button register" onClick={submitForm} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id="pre-footer"></div>
            </div>
            <Footer />
        </div>
    );
}

export default Register;