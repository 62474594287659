import React from "react";
import { Link } from "react-router-dom";

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mobileMenuOpened: false
        }

        this.mainSiteUrl = process.env.REACT_APP_MAIN_URL;
    }

    toggleMobileMenu = () => {
        this.setState({ mobileMenuOpened: !this.state.mobileMenuOpened });
    }

    render() {
        
        let cssClass = "touch-menu-la";
        cssClass += this.state.mobileMenuOpened ? " opened" : " closed";

        return (
            <>
            <div id="header" className="clearfix">
                <div className="logo"><Link to={this.mainSiteUrl}> </Link></div>
                <ul className="main-menu">
                    <li className="first"><Link to={this.mainSiteUrl}>Home</Link></li>
                    <li><a href={`${this.mainSiteUrl}how-it-works/`}>How it works?</a></li>
                    <li><a href={`${this.mainSiteUrl}support/`}>Support</a></li>
                    <li><Link to="/create-account/">Create account</Link></li>
                    <li><Link to="/login/" className="button login">Login</Link></li>
                </ul>
            </div>
            <div id="mobile-header" className="clearfix">
                <div id="menu-button" onClick={this.toggleMobileMenu}></div>
                <div className="logo"><Link to={this.mainSiteUrl}> </Link></div>
                <ul className="main-menu">
                    <li><Link to="/create-account/">Create account</Link></li>
                    <li><Link to="/login/" className="button login">Login</Link></li>
                </ul>      
            </div>
            <div id="mobile-menu" className={cssClass}> 
                <div id="menu-close" onClick={this.toggleMobileMenu}></div>
                <ul>
                    <li className="first"><Link to={this.mainSiteUrl}>Home</Link></li>
                    <li><a href={`${this.mainSiteUrl}how-it-works/`}>How it works?</a></li>
                    <li><a href={`${this.mainSiteUrl}support/`}>Support</a></li>
                    <li><Link to="/create-account/">Create account</Link></li>
                    <li><Link to="/login/">Login</Link></li>
                </ul>                    
            </div>
            </>
    )};
}

export default Header;