import React from 'react';
import Header from './Header';
import Footer from './Footer';

class Error404 extends React.Component {
    
    render() {
        return (
            <div>
                <div id="container">
                    <Header />
                    <div id="content">
                        <div class="wrapper-middle clearfix">
                            <h1>Not found</h1>
                        </div>
                    </div>
                    <div id="pre-footer"></div>
                </div>
                <Footer />                
            </div>
        );
    }
}

export default Error404;