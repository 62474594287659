import React from 'react';
import Header from './Header';
import Footer from './Footer';
import LoginForm from './parts/LoginForm';

class Login extends React.Component {
    render() {
        return (
            <div>
                <div id="container">
                    <Header />
                    <div id="content">
                        <LoginForm />
                    </div>
                    <div id="pre-footer"></div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Login;