import React from 'react';
import Header from './Header';
import Footer from './Footer';
import RecoveryForm from './parts/RecoveryForm';

class Recover extends React.Component {
    render() {
        return (
            <div>
                <div id="container">
                    <Header />
                    <div id="content">
                        <RecoveryForm />
                    </div>
                    <div id="pre-footer"></div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Recover;